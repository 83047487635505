<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">处理维修单</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>单号:</b></label> {{ model.SN }}
          </div>
          <div class="form-group">
            <label><b>分类:</b></label> {{ model.CAT_NAME }}
          </div>
          <div class="form-group">
            <label><b>位置:</b></label> {{ model.LOC_NAME }}
          </div>
          <div class="form-group">
            <label><b>问题描述</b></label>
            {{ model.DESCRIPTION }}
          </div>
          <div class="form-group" v-if="model.PIC_NAME">
            <!-- <el-image style="width: 100px;" :src="model.PIC_URL" :preview-src-list="[model.PIC_URL]">
            </el-image> -->
            <el-image style="width: 100px;" v-for="item in model.ImageKvs" :src="item.Value" :key="item.Key"
              :preview-src-list="previews">
            </el-image>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>维修人:</b></label>
            <el-select v-model="sel.TEA_ID" placeholder="请选择维修人" style="display: block">
              <el-option :label="it.NAME" :value="it.TEA_ID" v-for="it in repair" :key="it.TEA_ID" />
            </el-select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>抄送:</b></label>
            <el-select v-model="sel.Copy_ID" placeholder="请选择抄送人" multiple style="display: block">
              <el-option :label="it.NAME" :value="it.TEA_ID" v-for="it in copy" :key="it.TEA_ID" />
            </el-select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>外部人员:</b></label>
            <el-input type="input" v-model="model.MENDER" placeholder="如果是外部人员，请输入姓名" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="show = false">取消</el-button>
        <el-button icon="el-icon-time" type="warning" @click="submit()">分配</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        model: {},
        repair: [],
        copy: [],

        sel: {

        },
        teaList: [],
        previews: [],
        grid: {
          sea: {
            PAGE_SIZE: 100,
            PAGE_INDEX: 0,
          }
        }
      }
    },
    methods: {
      init(model) {
        this.show = true;
        this.model = JSON.parse((JSON.stringify(model)));
        if (this.model.ImageKvs != null) {
          this.previews = this.model.ImageKvs.map(o => {
            return o.Value;
          });
        }
        this.getTea();
      },
      getTea() {
        let self = this;
        self.repair = []
        self.copy = []
        this.whale.remote.getCollection({
          url: "/api/School/FIX/MenderApi/GetList",
          data: this.grid.sea,
          completed: function (its) {
            console.log(its)
            its.map(o => {
              if (o.ROLE == "REPAIR") self.repair.push(o);
              else if (o.ROLE == "COPY") {
                self.copy.push(o);
              }
            });
            self.teaList = its
          }
        })
      },
      submit() {
        this.model.MENDERS = [];
        let self = this;
        self.teaList.map(o => {
          if (o.TEA_ID == self.sel.TEA_ID) self.model.MENDERS.push(o);
          else if (self.sel.Copy_ID.indexOf(o.TEA_ID) >= 0) {
            self.model.MENDERS.push(o);
          }
        });
        if (self.sel.TEA_ID > 0)
          self.model.MENDER = ""
        else {
          if (self.model.MENDER) {
            self.model.MENDERS.push({
              NAME: self.model.MENDER,
              ROLE: "REPAIR"
            })
          }
        }
        console.log('model', self.model)

        this.whale.remote.getResult({
          url: "/api/School/FIX/NoteApi/Deliver",
          data: self.model,
          completed: function () {
            self.show = false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>