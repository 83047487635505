<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-hummer"> </i> 维修管理 | 维修记录</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增记录</el-button>
            <!-- <form id="fm_export" method="post" action="/api/School/FIX/Note/Export" target="_blank" style="display: inline-block; margin: 0px 10px">
            <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
            <input type="hidden" name="LOC_ID" v-model="grid.sea.LOC_ID" />
            <input type="hidden" name="CAT_ID" v-model="grid.sea.CAT_ID" />
            <input type="hidden" name="STA" v-model="grid.sea.STA" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-date-picker v-model="date.range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />

              <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类" clearable @change="filter"
                :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN', checkStrictly: true }" />
              <el-cascader v-model="loc.val" :options="loc.ls" placeholder="请选择位置" clearable @change="filter"
                :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" style="margin-left: 5px" />
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态" style="margin-left: 5px">
                <el-option label="新申请" value="1" />
                <el-option label="已撤消" value="2" />
                <el-option label="备修中" value="5" />
                <el-option label="已修好" value="7" />
                <el-option label="已评价" value="9" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">

              <el-table-column prop="SN" label="单号" width="120" align="center" />
              <el-table-column prop="CAT_NAME" label="分类" width="120" align="center" />
              <el-table-column prop="LOC_NAME" label="位置" width="120" align="center" />

              <el-table-column prop="DESCRIPTION" label="描述" width="200" />
              <el-table-column label="报修照片" width="50" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-for=" img in scope.row.ImageKvs" style="width: 50px;" :key="img.Key" :src="img.Value"
                    :preview-src-list="previews" />
                </template>
              </el-table-column>

              <el-table-column label="提交日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.CREATED_DT | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <el-table-column prop="SUB_NAME" label="提交人" width="70" align="center" />

              <el-table-column label="完成照片" width="50" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-if="scope.row.PIC_URL_FINISH" style="width: 50px; " :src="scope.row.PIC_URL_FINISH"
                    :preview-src-list="[scope.row.PIC_URL_FINISH]" />
                </template>
              </el-table-column>
              <!-- <el-table-column prop="RATE" label="评价" width="60" align="center" /> -->

              <el-table-column label="维修日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.FINISH_DT_STR | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <el-table-column prop="MENDER" label="维修人" width="70" align="center" />

              <el-table-column label="维修人签名" align="center" width="100">
                <template slot-scope="scope">
                  <img :src="scope.row.MENDER_SIGN_PIC_URL" width="100%" alt="">
                </template>
              </el-table-column>

              <el-table-column prop="STA_TXT" label="状态" width="70" align="center" />
              <el-table-column prop="COST" label="费用" width="60" align="center" />
              <el-table-column prop="MEM" label="备注" width="200" />

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.STA == 1 && scope.row.Power == 'ADMIN'" type="text" icon="el-icon-delete"
                    @click="rowDeliver(scope.row)">分配</el-button>

                  <el-button v-if="scope.row.STA == 5 && (scope.row.Power == 'ADMIN' || scope.row.IsMENDER == 1)"
                    type="text" icon="el-icon-delete" @click="rowPro(scope.row)">维修处理</el-button>

                  <el-button v-if="scope.row.STA == 7 && (scope.row.Power == 'ADMIN' || scope.row.IsMy == 1)"
                    type="text" icon="el-icon-delete" @click="rowConfirmed(scope.row)">验收</el-button>

                  <el-button v-if="scope.row.Power == 'ADMIN'" type="text" icon="el-icon-delete"
                    @click="rowDel(scope.row)">撤消</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="note_edit" @on-saved="filter" />
    </div>
    <div>
      <Pro ref="note_pro" @on-saved="filter" />
    </div>
    <div>
      <Deliver ref="note_deliver" @on-saved="filter" />
    </div>
    <div>
      <Confirmed ref="note_confirmed" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
  import Edit from "./edit";
  import Pro from "./pro";
  import Deliver from "./deliver";
  import Confirmed from "./confirmed";
  import Wgrid from "@/components/wgrid";
  import jsFileDownload from 'js-file-download'
  export default {
    name: "index",
    components: { Wgrid, Edit, Pro, Deliver, Confirmed },
    data() {
      return {
        mender_role: '',
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
          },
          ls: [],
          total: 0,
          loading: false
        },
        cat: {
          ls: [],
          val: []
        },
        loc: {
          ls: [],
          val: []
        },
        date: { range: [] },
        previews: []
      }
    },
    created() {
      this.getCat();
      this.getLoc();
      this.getList();
    },
    methods: {
      filter() {
        console.log(this.date.range)
        let n = this.date.range.length
        if (n > 0) {
          this.grid.sea.DTS = this.date.range[0]
          this.grid.sea.DTE = this.date.range[1]
        }

        n = this.cat.val.length;
        if (n > 0) {
          this.grid.sea.CAT_ID = this.cat.val[n - 1];
        } else {
          this.grid.sea.CAT_ID = 0;
        }

        n = this.loc.val.length;
        if (n > 0) this.grid.sea.LOC_ID = this.loc.val[n - 1]; else this.grid.sea.LOC_ID = 0;
        // this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        self.previews = []
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/FIX/NoteApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;

            its.forEach(x => {
              if (x.ImageKvs != null) {
                x.ImageKvs.forEach(y => {
                  self.previews.push(y.Value)
                })
              }
            });
          }
        })
      },
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/FIX/CatApi/GetTree",
          completed: function (its) {
            self.cat.ls = its.DATA;
          }
        })
      },
      getLoc() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/FIX/LocationApi/GetTree",
          completed: function (its) {
            self.loc.ls = its.DATA;
          }
        })
      },
      rowEdit(r) {
        this.$refs.note_edit.init(r);
      },
      rowPro(r) {
        this.$refs.note_pro.init(r);
      },
      rowDeliver(r) {
        this.$refs.note_deliver.init(r);
      },
      rowConfirmed(r) {
        this.$refs.note_confirmed.init(r);
      },
      rowDel(r) {
        this.whale.delConfirm(() => {
          let self = this;
          this.whale.remote.getResult({
            url: "/api/School/FIX/NoteApi/Cancel",
            data: { ID: r.ID },
            completed: function () {
              //self.filter();
              self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
            }
          })
        });
      },
      exportExcel() {
        let self = this;
        this.grid.loading = true;
        let n = this.date.range.length
        if (n > 0) {
          this.grid.sea.DTS = this.date.range[0]
          this.grid.sea.DTE = this.date.range[1]
        }
        const formData = new FormData()
        formData.append('DTS', this.grid.sea.DTS || '')
        formData.append('DTE', this.grid.sea.DTE || '')
        formData.append('KEY', this.grid.sea.KEY || '')
        formData.append('LOC_ID', this.grid.sea.LOC_ID || '')
        formData.append('CAT_ID', this.grid.sea.CAT_ID || '')
        formData.append('STA', this.grid.sea.STA || '')
        this.whale.remote.getExport({
          url: "/api/School/FIX/Note/Export",
          data: formData,
          completed: function (its) {
            // console.log('exls', its.Data)
            // self.whale.downLoad(its.Data)
            jsFileDownload(its, '导出维修管理维修记录.xls')
            self.grid.loading = false;
          }
        })
      }
    }
  }
</script>