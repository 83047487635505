<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-direction-alt"></i> 首页</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row font">
          欢迎访问云管家
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home"
}
</script>

<style scoped>
.font{
  font-size: 20px;
  padding: 20px ;
}
</style>