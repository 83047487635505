<template>
  <div>
    <div class="box box1">
      <group-item v-for="(item,index1) in andList" :key="index1" :item="item" @del="del"></group-item>
      <el-button type="primary" size="mini" icon="el-icon-plus" style="margin: 10px 5px" round
                 @click="addConditionGroup('AND')">
        且条件
      </el-button>
    </div>
<!--    或条件-->
<!--    <div v-for="(item,index2) in orList" :key="index2+'2'" class="box box2">-->
<!--      <group-item :item="item" @del="del"></group-item>-->
<!--    </div>-->
<!--    <el-button type="primary" size="mini" icon="el-icon-plus" style="margin-left: 15px"  round-->
<!--               @click="addConditionGroup('OR')">-->
<!--      或条件-->
<!--    </el-button>-->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import GroupItem from "./ConditionGroupItemConfigNew.vue"

export default {
  name: "ConditionNodeConfigNew",
  components: {draggable, GroupItem},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode
    },
    orList(){
      return this.config.groups.filter(item=>item.groupType=='OR')
    },
    andList(){
      return this.config.groups.filter(item=>item.groupType=='AND')
    },
    select() {
      return this.config.assignedUser || []
    },
    nowNodeLeave() {
      return this.prioritySortList.indexOf(this.selectedNode)
    },
    //条件节点
    prioritySortList() {
      let node = this.$store.state.nodeMap.get(this.selectedNode.parentId)
      console.log(this.selectedNode.id, node)
      if (node) {
        return node.branchs || []
      }
      return []
    }
  },
  data() {
    return {
      sortOption: {
        animation: 300,
        chosenClass: 'choose',
        scroll: true,
        sort: true
      }
    }
  },
  methods: {
    del(id){
      let i = this.config.groups.findIndex(item=>item.id==id)
      this.config.groups.splice(i,1)
    },
    addConditionGroup(groupType) {
      this.config.groups.push({
        cids: [],
        groupType: groupType,
        conditions: [],
        id:this.config.groups.length+1
      })
    },
    selectUser() {
      this.showOrgSelect = true
    },
    selected(select) {
      console.log(select)
      this.showOrgSelect = false
      select.forEach(val => this.select.push(val))
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  background: #F5F9FB;
  margin-bottom: 10px;
  padding: 0 10px;
}


.choose {
  border-radius: 5px;
  margin-top: 2px;
  background: #f4f4f4;
  border: 1px dashed #1890FF !important;
}

.drag-hover {
  color: #1890FF
}

.drag-no-choose {
  cursor: move;
  background: #f8f8f8;
  border-radius: 5px;
  margin: 5px 0;
  //height: 25px;
  line-height: 25px;
  padding: 5px 10px;
  border: 1px solid #ffffff;

  div {
    display: inline-block;
    font-size: small !important;
  }

  div:nth-child(2) {
    float: right !important;
  }
}
</style>
