<template>
  <div class="navs">
    <router-link to="/home" class="navHead">
      <img src="../../assets/navImgs/cdtb_sy.png" alt="">
      <span class="btn-sy">首页</span>
    </router-link>
    <div class="menu-wrap">
      <div class="custom-sub">
        <div @click="getItems(item)" v-for="item in ls" :key="item.ID" class="custom-item"
          :class="{ active: activeId == item.ID }">
          <img :src="getImg(item.CSS)" alt="">
          <p>{{ item.MENU_NAME }}</p>
        </div>
      </div>
      <div class="custom-items" v-if="isMenuActive">
        <el-menu :default-active="activePath" @select="select2" v-for="child in children" :key="child.ID" class="menu">
          <!--二级 有目录-->
          <el-submenu v-if="child.CHILDREN && child.CHILDREN.length > 0" :index="child.URL">
            <span slot="title">
              <img class="custom-icon2" :src="getImg(child.CSS)" alt="">
              {{ child.MENU_NAME }}
            </span>
            <!--三级-->
            <el-menu-item v-for="child2 in child.CHILDREN" :key="child2.ID" :index="child2.URL">
              <span class="custom-icon3">{{ child2.MENU_NAME }}</span>
            </el-menu-item>
          </el-submenu>
          <!--二级 无目录-->
          <el-menu-item :key="child.ID" v-else :index="child.URL">
            <img class="custom-icon2" :src="getImg(child.CSS)" alt="">
            <span slot="title">{{ child.MENU_NAME }}</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeId: '',
      activePath: '',
      isCollapse: true,
      ls: [],
      children: [],
      isMenuActive: false
    };
  },
  computed: {
    getImg() {
      return (imgName) => {
        try {
          const module = require(`@/assets/navImgs/${imgName}`);
          return module
        } catch (error) {
          // console.error('Failed to load module:', error);
          const module = require('@/assets/navImgs/cdtb_zcd_bj.png');
          return module
        }
      }
    }
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/ACL/MenuApi/GetTreeV2",
        data: { IS_POWER: 0, IS_FORM: 1 },
        completed: function (its) {
          self.ls = its.DATA;
          self.activeId = self.findFirstLevelIdByPath(self.ls, self.$route.path)
          self.activePath = self.$route.path
          if (!self.activeId) {
            self.getItems(self.ls[0])
          } else {
            self.children = self.ls.find(item => item.ID == self.activeId).CHILDREN
          }
          self.isMenuActive = true
        }
      })
    },
    getItems(item) {
      this.isMenuActive = false;
      this.$nextTick(() => {
        this.children = item.CHILDREN
        this.activeId = item.ID
        this.$nextTick(() => {
          this.isMenuActive = true;
        });
      });
    },
    select2(path, paths) {
      this.activePath = path
      this.$router.push(path)
    },

    findFirstLevelIdByPath(tree, targetPath) {
      for (let node of tree) {
        if (node.URL === targetPath || this.isChildPathMatch(node.CHILDREN, targetPath)) {
          // console.log(123, node)
          return node.ID; // 返回当前节点的id作为第一级目录的id
        }
      }
      return undefined; // 没有找到目标路径时返回 undefined
    },

    // 辅助函数：检查子节点中是否有路径匹配
    isChildPathMatch(children, targetPath) {
      if (!Array.isArray(children) || children.length === 0) {
        return false;
      }
      for (let child of children) {
        if (child.URL === targetPath || this.isChildPathMatch(child.CHILDREN, targetPath)) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>

<style scoped lang="less">
.navHead {
  display: block;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e6ecef;
  cursor: pointer;

  img {
    margin-left: 25px;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 15px;
    color: #566e8c;
  }

  .btn-sy {
    line-height: 60px;
    vertical-align: middle;
  }
}

.menu-wrap {
  height: 100%;
  display: flex;
}

.custom-sub {
  width: 70px;
}

.custom-items {
  background: #fff;
  min-height: calc(100vh - 42px - 56px);
  overflow: auto;
  flex: 1;
}

.custom-icon3 {
  padding-left: 15px;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #b4c0cb;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.custom-icon2 {
  margin-right: 5px;
}

.el-menu-item {
  height: auto;
  min-height: 56px;
}

::v-deep .custom-sub {
  >.el-submenu__title {
    height: auto;
    min-height: 56px;
    padding: 0 4px !important;

    &:hover {
      background: rgb(236, 245, 255) !important;
    }
  }
}

.custom-item {
  cursor: pointer;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
  border-radius: 4px;

  p {
    font-size: 13px;
    color: #536973;
    display: block;
    line-height: 1;
    margin: 0;
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.active {
    background-color: #fff;
    z-index: 99;
  }
}
</style>