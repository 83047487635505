<template>
  <el-dialog :visible="visible" title="基本信息" :before-close="handleClose">
    <div class="c1">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="流程类型">
          <el-select v-model="form.SchemeType" @change="changeType">
            <el-option v-for="item in config.type" :value="item.Key" :label="item.Value"
                       :key="item.Key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="form.SchemeName"></el-input>
        </el-form-item>
        <el-form-item label="流程编号">
          <el-input v-model="form.SchemeCode"></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker v-model="form.CreateTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input type="textarea" v-model="form.Description" :rows="5"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" align="center">
      <el-button @click="handleClose">关闭</el-button>
      <el-button @click="link" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "dialog_edit",
  props:['visible','id'],
  data(){
    return{
      config: {},
      form: {
        SchemeCode: new Date().getTime(),
        CreateTime:new Date(),
        SchemeVersion:1,
        SchoolId:JSON.parse(localStorage.getItem('userInfo')).SCH_ID,
        SchemeCanUser:'test',
        AuthorizeType:2,
        StartUserType:2,
        SchemeType:'',
        SchemeName:'',
        Description:'',
      },
    }
  },
  created() {
    this.getConfig()
  },
  methods:{
    handleClose(){
      this.$emit('close')
    },
    getConfig() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/scheme/config",
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.config = AppendData
            if(self.id){
              self.getDetail(self.id)
            }
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getDetail(id){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/scheme/detail?id="+id,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.form = AppendData
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    changeType(val){
      let item = this.config.type.find(v=>v.Key==val)
      this.form.SchemeType = item.Key
      this.form.SchemeName = item.Value
      this.form.DbName = item.Key
    },
    link(){
      if(!this.form.SchemeType){
        this.$message.warning('请选择流程')
        return
      }
      if(!this.form.SchemeName){
        this.$message.warning('请输入标题')
        return
      }

      sessionStorage.setItem('formData',JSON.stringify(this.form))
      this.$router.push('/org/process?key='+this.form.SchemeType)
    }
  }
}
</script>

<style scoped>

</style>