<template>
    <div class="wrapper">
        <div class="left_aside">
            <div class="sidebar">
                <div id="menu" role="navigation">
<!--                    <Nav></Nav>-->
                  <Nav1></Nav1>
                </div>
            </div>
        </div>

        <div class="right-aside view-port-height">
            <router-view :key="key" />
        </div>
    </div>
</template>
  
<script>
// import Nav from "../components/wnav.vue";
// import Nav from '@/components/layout/Nav'
import Nav1 from '@/components/layout/Nav1'
export default {
    components: { Nav1 },
    data() {
        return {
            token: localStorage.getItem("loginToken"),
            schoolName: '',
            userName: ''
        }
    },
    mounted() {
        this.get()
    },
    methods: {
        get() {
            this.whale.remote.getResult({
                url: "/api/School/ORG/TeacherApi/Mine",

                completed: function (m) {
                    // console.log('ll',m)
                    // this.schoolName = m.DATA.SCHOOL.NAME
                    // console.log(this.schoolName)
                    // this.userName = m.DATA.NAME
                    $("#msch_name").html(m.DATA.SCHOOL.NAME);
                    $("#muser_name").html(m.DATA.NAME);
                }
            })
        }
    },
    computed: {
        key() { return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date() }
    },
}
</script>
<style>
.w-block {
    border-radius: 4px;
    background: #f5f5f5;
    color: #333;
    margin: 5px 0px;
    border: solid 1px #ccc
}

.left_aside{
  background: #f3f8fa;
}

.el-table th {
    color: #333;
}

.el-table td {
    height: 40px
}

.el-dialog__wrapper {
    z-index: 999
}

.el-dialog__header {
    border-bottom: solid 1px #eceeef;
    background-color: #fcb410 !important;
    line-height: 24px;
    font-size: 18px;
    color: #fff;
}

.el-dialog__headerbtn {
    text-shadow: 0 1px 0 #ffffff;
    opacity: .5;
    background: transparent;
}

.el-dialog__footer {
    border-top: 1px solid #eceeef
}

.el-dialog__title {
    color: #fff
}

.swal2-container.swal2-center {
    z-index: 5000;
}

button:focus {
    outline: none
}

.sidebar,#menu{
  height: 100%;
}

.content-header{
  border-bottom: 1px solid #e6ecef;
}
</style>
  
  
  